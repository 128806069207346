import { Box } from "@mui/material";
import Navbar from "components/Navbar/Navbar";
import Sidebar1 from "components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import "setup/scss/RequireAuth.scss";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./Auth";

export default function RequireAuth() {
  const { currentUser, userInfo } = useAuth();
  const [role, setRole] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // console.log(userInfo.usertype.includes("2009") ? "Admin" : "User");
  const location = useLocation();

  const [isSidebar, setIsSidebar] = useState(true);
  useEffect(() => {
    if (userInfo !== undefined) {
      setRole(userInfo.usertype === undefined ? [] : userInfo.usertype);
      setIsLoading(false);
    }
  }, [userInfo]); // Depend on userInfo
  // const role =
  //   userInfo.usertype === undefined
  //     ? []
  //     : userInfo.usertype
  // .includes("2009")
  // ? "Admin"
  // : "User";

  // console.log(role)

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return currentUser ? (
    role.includes("2001") ? (
      <Box className="app">
        <Box className="sidebar">
          <Sidebar1 role={role} />
        </Box>
        <main className="content">
          <Navbar setIsSidebar={isSidebar} />
          <Outlet context={{ role }} />
        </main>
      </Box>
    ) : (
      <Navigate to="/" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}
