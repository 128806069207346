import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useAuth } from 'setup/Auth';
import emailAlreadyExists from 'setup/DbCalls';
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import 'pages/SignUp/SignUp.scss'
// import symco from 'content/symcoicon.png'
import epsaconnectImage from "content/epsaconnect.jpg";

import { Visibility, VisibilityOff } from '@mui/icons-material';

 
export default function Signup() {
  const [activeStep, setActiveStep] = React.useState(0);
  const emailRef = useRef()
  const emailRef2 = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState("")
  const [error2, setError2] = useState("")
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
 
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNext = () => {
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };



  const handleReset = () => {
    setError ("");
    setError2 ("");
    setActiveStep(0);
  };

  async function handleSubmitemail(e) {
    e.preventDefault()

    
    try {
        setError("")
        setLoading(true)
        emailRef2.current = emailRef.current
        const chk = await emailAlreadyExists(emailRef.current.value)
        
        if (chk === null) {
          throw new Error('Email does not exist please contact ....');
        }
        else {
          handleNext();
        } 
      } catch(err) {
        setError(err.message)
      }

    setLoading(false)
  }


   async function handleSubmitPass(e) {
    e.preventDefault()
    console.log(passwordRef.current.value)
    console.log(emailRef2.current.value)
    console.log(passwordConfirmRef.current.value)
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError2("Passwords do not match")
    }

    try {
      setError2("")
      setLoading2(true)
      console.log("handle Submit pass")
      await 
      signup(emailRef2.current.value, passwordRef.current.value)

      navigate("/VendorList")
    } catch(err2) {
    
        switch (err2.code) {
           case "auth/email-already-in-use":
            setError2(`email address ${emailRef2.current.value} already in use.`);
             break;
           case 'auth/invalid-email':
            setError2(`email address ${emailRef2.current.value} is invalid.`);
             break;
           case 'auth/operation-not-allowed':
            setError2(`error during sign up.`);
             break;
           case 'auth/weak-password':
            setError2('password is not strong enough. add additional characters including special characters and numbers.');
             break;
           default:
            setError2(err2.code);
             break;
         }
    }

    setLoading2(false)
  }
   
  return (
<Box className = "signup">
  <Box className = "leftbox">
  <img src={epsaconnectImage} alt="Circular" className="image" />

  </Box>
  <Box className= "signupBox" >
  <Box className= "signupInnerBox" >

      <Box className= "signupinnerinner" sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
            <Step  key={0}>
                <StepLabel
                  optional={                  
                      <Typography variant="caption">First Step</Typography>                 
                  }
                >
                  Check Email
                </StepLabel>
                <StepContent>
                <FormControl onSubmit={handleSubmitemail} >
                  <Box>           
                    <Typography variant="caption">Please insert Email </Typography>            
                    <TextField
                      required
                      id="outlined-required"
                      label="Required"
                  
                      inputRef={emailRef}
                    />
                    {error && <Alert  severity="error" >{error}</Alert>}
                   
                  </Box>
                  <Box >
                    <div>
                      <Button
                        type='submit'
                        variant="contained"
                        onClick={handleSubmitemail}
                        sx={{  backgroundColor:'#3D4832'}}
                        disabled={loading}
                      >
                        { 'Check Email'}
                      </Button>
                  
                    </div>
                  </Box>
                  </FormControl >
                </StepContent>
              </Step>                
              <Step key={1}>
            <StepLabel
              optional={             
                  <Typography variant="caption">Last Step</Typography>      
              }
            >
              Please insert password
            </StepLabel>
            <StepContent>
            <FormControl onSubmit={handleSubmitPass} >
              <Box> 
              {
                  <Typography variant="caption">Please insert password </Typography>
              }
  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              inputRef={passwordRef}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            </FormControl>
  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password1">Confirm Password</InputLabel>
            <OutlinedInput
              inputRef={passwordConfirmRef}
              id="outlined-adornment-password1"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
  </FormControl>       
                {error2 && <Alert  severity="error" >{error2}</Alert>}
         
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    type='submit'
                    variant="contained"
                    onClick={handleSubmitPass}
                    sx={{ mt: 1, mr: 1 ,backgroundColor:'#3D4832'}}
                    disabled={loading2}
                  >
                    Signup
                  </Button>        
                </div>
              </Box>
              </FormControl >
            </StepContent>
          </Step>
        
        </Stepper>
        {activeStep === 1 && (
          <Paper square elevation={0} sx={{ p: 3,backgroundColor:'#fff0' }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1, color:'#3D4832'}}>
              Reset
            </Button>
          </Paper>
        )}
              <Grid item>
                <Link sx={{color:'#3D4832'}} href="/" variant="body2">
                  Already have an account? Login
                </Link>
              </Grid>
          
          </Box>
        </Box>
      </Box>
  </Box>
  );
}