import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";

import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "pages/LMISLearnerProfile/LMISLearnerProfile.scss";
import { GetVendorLMISDoc } from "setup/DbCalls";
import {
  uploadDocumentToFolder,
  fetchFilesFromAssessmentFolder,
  fetchFilesFromFolder,
  handleFileUploadLer,
  fetchAssessmentFolders,
  createFolderInAssessments,
  deleteFileFromFolder,
  deleteFolder,
  deleteAssessmentFile,
} from "setup/DBWrites.js";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { AssessmentSharp } from "@mui/icons-material";
import { updateLearnerDetails } from "setup/DBWrites.js";
import { useAuth } from "setup/Auth";

export default function LMISLearnerProfile() {
  const { role } = useOutletContext();
  // console.log(role)
  const { id, id2 } = useParams();
  const [files, setFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [openLearnerEdit, setOpenLearnerEdit] = useState(false);
  const [learner, setLearner] = useState();
  const [openPersonalDetails, setOpenPersonalDetails] = useState(false);
  const [openIDdoc, setOpenIDdoc] = useState(false);
  const [openCertificates, setOpenCertificates] = useState(false);
  const [openCV, setOpenCV] = useState(false);

  const [loadingIDdoc, setLoadingIDdoc] = useState(false);
  const [loadingCertificates, setLoadingCertificates] = useState(false);
  const [loadingCV, setLoadingCV] = useState(false);

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadModalMessage, setUploadModalMessage] = useState("");

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({ name: "", folder: "" });

  const [actionModalOpen, setActionModalOpen] = useState(false); // New state for action modal visibility
  const [actionModalMessage, setActionModalMessage] = useState(""); // New state for action modal message

  const [deleteFolderConfirmModalOpen, setDeleteFolderConfirmModalOpen] =
    useState(false);
  const [folderToDelete, setFolderToDelete] = useState({ name: "" });

  const [deleteAssessmentFileModalOpen, setDeleteAssessmentFileModalOpen] =
    useState(false);
  const [assessmentFileToDelete, setAssessmentFileToDelete] = useState({
    name: "",
    folder: "",
  });

  const [saving, setSaving] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [idDocs, setIdDocs] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [cvs, setCvs] = useState([]);

  const [loadingIdDocs, setLoadingIdDocs] = useState(false);
  //const [loadingCertificates, setLoadingCertificates] = useState(false);
  const [loadingCVs, setLoadingCVs] = useState(false);
  const [idDocFiles, setIdDocFiles] = useState([]);
  const [loadingIdDoc, setLoadingIdDoc] = useState(false);

  const [certificateFiles, setCertificateFiles] = useState([]);

  const [cvFiles, setCvFiles] = useState([]);
  //const [loadingCV, setLoadingCV] = useState(false);
  //const [loadingCertificates, setLoadingCertificates] = useState(false);

  //   Assessment

  const [assessmentFolders, setAssessmentFolders] = useState([]);
  const [openAssessments, setOpenAssessments] = useState(false);
  const [loadingAssessments, setLoadingAssessments] = useState(false);

  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  const [selectedAssessmentFolder, setSelectedAssessmentFolder] = useState("");
  const [assessmentFiles, setAssessmentFiles] = useState([]);

  const [loadingAssessmentFiles, setLoadingAssessmentFiles] = useState(false);

  const { todo } = GetVendorLMISDoc(id, id2);

  const handleClickEditLearner = () => setOpenLearnerEdit(!openLearnerEdit);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (todo) {
      setLearner(todo);
    }
  }, [todo]);

  useEffect(() => {
    if (id && id2 && selectedFolder === "IDdoc") {
      setLoadingIdDocs(true);
      fetchFilesFromFolder(id, id2, "IDdoc")
        .then((fetchedFiles) => {
          setIdDocFiles(fetchedFiles); // Update to use setIdDocFiles
        })
        .catch((error) => {
          console.error(`Error fetching ID documents:`, error);
        })
        .finally(() => {
          setLoadingIdDocs(false);
        });
    }
  }, [id, id2, selectedFolder]);

  useEffect(() => {
    if (id && id2 && selectedFolder === "Certificates") {
      setLoadingCertificates(true);
      fetchFilesFromFolder(id, id2, "Certificates")
        .then((fetchedFiles) => {
          setCertificateFiles(fetchedFiles); // Update to use setCertificateFiles
        })
        .catch((error) => {
          console.error(`Error fetching certificates:`, error);
        })
        .finally(() => {
          setLoadingCertificates(false);
        });
    }
  }, [id, id2, selectedFolder]);

  useEffect(() => {
    if (id && id2 && selectedFolder === "CV") {
      setLoadingCV(true);
      fetchFilesFromFolder(id, id2, "CV")
        .then((fetchedFiles) => {
          setCvFiles(fetchedFiles);
        })
        .catch((error) => {
          console.error(`Error fetching CV documents:`, error);
        })
        .finally(() => {
          setLoadingCV(false);
        });
    }
  }, [id, id2, selectedFolder]);

  // You might need to adjust dependencies based on your actual variables

  const handleDelete = async (fileName) => {
    if (window.confirm(`Are you sure you want to delete ${fileName}?`)) {
      try {
        console.log(`File ${fileName} deleted successfully`);
        setFiles(files.filter((file) => file.name !== fileName));
      } catch (error) {
        console.error(`Error deleting file ${fileName}:`, error);
      }
    }
  };

  useEffect(() => {
    if (id && id2 && selectedFolder === "Assessments") {
      const fetchFolders = async () => {
        setLoadingAssessments(true);
        try {
          const fetchedFolders = await fetchAssessmentFolders(id, id2);
          setAssessmentFolders(fetchedFolders);
        } catch (error) {
          console.log(`Error fetching assessment folders:`, error);
        } finally {
          setLoadingAssessments(false);
        }
      };

      fetchFolders();
    }
  }, [id, id2, selectedFolder]);

  const createNewFolder = async (folderName) => {
    if (!folderName.trim()) {
      setActionModalMessage("Please provide a valid folder name.");
      setActionModalOpen(true);
      return;
    }

    try {
      await createFolderInAssessments(id, id2, folderName);
      setActionModalMessage("Folder created successfully");
      // Refresh the folders list. Here you would either refetch folders or add the new one to the list
      // This is an example. You might need to adjust it based on how you manage state.
      setAssessmentFolders((prevFolders) => [
        ...prevFolders,
        { name: folderName },
      ]);
      // Optionally close the create folder modal if you have one
      setOpenCreateFolderModal(false);
    } catch (error) {
      console.error("Error creating folder:", error);
      setActionModalMessage("Error creating folder");
    }

    setActionModalOpen(true);
  };

  useEffect(() => {
    if (id && id2 && selectedAssessmentFolder) {
      setLoadingAssessmentFiles(true);
      const fetchAssessmentFiles = async () => {
        try {
          const files = await fetchFilesFromAssessmentFolder(
            id,
            id2,
            selectedAssessmentFolder
          );
          setAssessmentFiles(
            files.filter((file) => file !== null && file !== undefined)
          );
        } catch (error) {
          console.log(
            `Error fetching files from assessment folder ${selectedAssessmentFolder}:`,
            error
          );
        } finally {
          setLoadingAssessmentFiles(false);
        }
      };
      fetchAssessmentFiles();
    } else {
      setAssessmentFiles([]);
    }
  }, [id, id2, selectedAssessmentFolder]);

  const ActionModal = () => (
    <Dialog open={actionModalOpen} onClose={() => setActionModalOpen(false)}>
      <DialogTitle>Action Status</DialogTitle>
      <DialogContent>
        <DialogContentText>{actionModalMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setActionModalOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const onClickSave = async () => {
    setIsSaving(true);
    const result = await updateLearnerDetails(id, id2, learner);
    setIsSaving(false);

    if (result) {
      setActionModalMessage("Learner details updated successfully.");
      setActionModalOpen(true);
      setOpenLearnerEdit(false);
    } else {
      alert("Failed to update learner details.");
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setLearner((prevLearner) => ({
      ...prevLearner,
      [name]: value,
    }));
  };

  // const handleConfirmDelete = async () => {
  //   const isSuccess = await deleteFileFromFolder(id, id2, fileToDelete.folder, fileToDelete.name);
  //   if (isSuccess) {
  //     // Update the respective state based on the folder from which the file is being deleted
  //     if (fileToDelete.folder === "CV") {
  //       setCvFiles(cvFiles.filter(file => file.name !== fileToDelete.name));
  //     } else if (fileToDelete.folder === "Certificates") {
  //       setCertificateFiles(certificateFiles.filter(file => file.name !== fileToDelete.name));
  //     } else if (fileToDelete.folder === "IDdoc") {
  //       setIdDocFiles(idDocFiles.filter(file => file.name !== fileToDelete.name));
  //     }
  //     // Close the confirmation modal
  //     setDeleteConfirmModalOpen(false);
  //   } else {
  //     console.error("Failed to delete the file.");
  //     // Optionally, handle error feedback to the user
  //   }
  // };

  const handleUpload = async (e) => {
    setUploadModalOpen(true);
    setUploadModalMessage("Uploading document...");

    console.log("Current user in handleUpload:", currentUser); // Add this line

    try {
      const newFile = await uploadDocumentToFolder(
        e,
        id,
        id2,
        "Assessments",
        selectedAssessmentFolder,
        currentUser
      );

      if (newFile) {
        setAssessmentFiles((prevFiles) => [...prevFiles, newFile]);
        setUploadModalMessage("Document uploaded successfully!");
        console.log("Uploaded file:", newFile); // Add this log
      } else {
        setUploadModalMessage("Failed to upload document.");
      }
    } catch (error) {
      setUploadModalMessage(`Error uploading document: ${error.message}`);
    } finally {
      setTimeout(() => setUploadModalOpen(false), 2000);
    }
  };
  const handleDeleteAssessmentFile = async () => {
    if (!assessmentFileToDelete.name || !assessmentFileToDelete.folder) {
      console.error("Invalid file to delete");
      setActionModalMessage("Error: Invalid file to delete");
      setActionModalOpen(true);
      setDeleteAssessmentFileModalOpen(false);
      return;
    }

    try {
      const success = await deleteAssessmentFile(
        id,
        id2,
        assessmentFileToDelete.folder,
        assessmentFileToDelete.name
      );
      if (success) {
        setAssessmentFiles((prevFiles) =>
          prevFiles.filter(
            (file) => file && file.name !== assessmentFileToDelete.name
          )
        );
        setActionModalMessage("File deleted successfully");
      } else {
        setActionModalMessage("Failed to delete the file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      setActionModalMessage(`Error deleting file: ${error.message}`);
    } finally {
      setActionModalOpen(true);
      setDeleteAssessmentFileModalOpen(false);
    }
  };

  return (
    <Box>
      <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
        <DialogTitle>Upload Status</DialogTitle>
        <DialogContent>
          <DialogContentText>{uploadModalMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Box className="viewHeader">
        <h2>
          Learner Profile:{id}/{id2}
        </h2>
      </Box>

      {learner ? (
        <Box>
          <Box className="viewContent">
            <Box className="viewContentInner">
              <List
                className="listLearner"
                // sx={{
                //   width: "90%",
                //   bgcolor: "background.paper",
                // }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {/* Personal Details */}
                <ListItemButton
                  onClick={() => setOpenPersonalDetails(!openPersonalDetails)}
                >
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Personal Details" />
                  {openPersonalDetails ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPersonalDetails} timeout="auto" unmountOnExit>
                  {openLearnerEdit ? (
                    <List component="div" disablePadding>
                      {Object.entries(learner).map(([key, value], index) => (
                        <ListItem key={index} divider>
                          <ListItemText
                            primary={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{key.replace(/_/g, " ")}</span>
                                <TextField
                                  key={key}
                                  name={key}
                                  className="textfield"
                                  // label={key
                                  //   .replace(/_/g, " ")
                                  //   .replace(/\b(\w)/g, (s) => s.toUpperCase())} // Capitalize label
                                  value={value}
                                  onChange={handleFormChange}
                                  fullWidth
                                  // margin="normal"
                                  variant="outlined"
                                />
                              </div>
                            }
                          />
                        </ListItem>
                      ))}
                      <ListItemButton
                        className="companyDetailsButton"
                        onClick={onClickSave}
                        disabled={isSaving}
                      >
                        <Box className="companyDetailsButtonInner">
                          <ListItemIcon>
                            {isSaving ? (
                              <CircularProgress size={24} />
                            ) : (
                              <AddIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary="Save" />
                        </Box>
                      </ListItemButton>
                      <ListItemButton
                        className="companyDetailsButton"
                        onClick={() => {
                          handleClickEditLearner();
                        }}
                      >
                        <Box className="companyDetailsButtonInner">
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText primary="Cancel" />
                        </Box>
                      </ListItemButton>
                    </List>
                  ) : (
                    <List component="div" disablePadding>
                      {Object.entries(learner).map(([key, value], index) => (
                        <ListItem key={index} divider>
                          <ListItemText
                            primary={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{key.replace(/_/g, " ")}</span>
                                <span>{value}</span>
                              </div>
                            }
                          />
                        </ListItem>
                      ))}
                      <ListItemButton
                        className="companyDetailsButton"
                        onClick={() => {
                          handleClickEditLearner();
                        }}
                      >
                        <Box className="companyDetailsButtonInner">
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText primary="Edit Learner Details" />
                        </Box>
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>

                {/* Assessments */}
                <ListItemButton
                  onClick={() => {
                    setSelectedFolder("Assessments");
                    setOpenAssessments(!openAssessments);
                  }}
                >
                  <ListItemIcon>
                    <AssessmentSharp />
                  </ListItemIcon>
                  <ListItemText primary="Assessments" />
                  {openAssessments ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={openAssessments}
                  timeout="auto"
                  unmountOnExit
                  style={{ marginLeft: "30px" }}
                >
                  <List component="div" disablePadding>
                    {loadingAssessments && <CircularProgress />}
                    {!loadingAssessments &&
                      assessmentFolders
                        .filter(
                          (folder) => folder !== null && folder !== undefined
                        )
                        .map((folder, index) => (
                          <React.Fragment key={index}>
                            <ListItemButton
                              className="folder"
                              onClick={() => {
                                const isCurrentlySelected =
                                  selectedAssessmentFolder === folder.name;
                                setSelectedAssessmentFolder(
                                  isCurrentlySelected ? "" : folder.name
                                );
                              }}
                            >
                              <ListItemIcon>
                                <FolderOutlinedIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={folder.name || "Unnamed Folder"}
                              />
                              {selectedAssessmentFolder === folder.name ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                              {role.includes("2009") && (
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFolderToDelete({ name: folder.name });
                                    setDeleteFolderConfirmModalOpen(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </ListItemButton>
                            <Collapse
                              in={selectedAssessmentFolder === folder.name}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {selectedAssessmentFolder === folder.name &&
                                  (loadingAssessmentFiles ? (
                                    <CircularProgress />
                                  ) : (
                                    assessmentFiles
                                      .filter(
                                        (file) =>
                                          file !== null && file !== undefined
                                      )
                                      .map((file, fileIndex) => (
                                        <ListItem key={fileIndex} divider>
                                          <ListItemIcon>
                                            <AttachFileOutlinedIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={
                                              file.name || "Unnamed File"
                                            }
                                            secondary={`Uploaded: ${
                                              file.uploadDate
                                                ? new Date(
                                                    file.uploadDate
                                                  ).toLocaleString()
                                                : "Unknown"
                                            } by ${
                                              file.uploaderEmail || "Unknown"
                                            }`}
                                          />
                                          <ListItemIcon>
                                            <IconButton
                                              edge="end"
                                              aria-label="view"
                                              onClick={() =>
                                                file.url &&
                                                window.open(file.url, "_blank")
                                              }
                                            >
                                              <DownloadIcon />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon>
                                            {role.includes("2009") && (
                                              <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (file && file.name) {
                                                    setAssessmentFileToDelete({
                                                      name: file.name,
                                                      folder:
                                                        selectedAssessmentFolder,
                                                    });
                                                    setDeleteAssessmentFileModalOpen(
                                                      true
                                                    );
                                                  } else {
                                                    console.error(
                                                      "Invalid file object:",
                                                      file
                                                    );
                                                    setActionModalMessage(
                                                      "Error: Invalid file"
                                                    );
                                                    setActionModalOpen(true);
                                                  }
                                                }}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            )}
                                          </ListItemIcon>
                                        </ListItem>
                                      ))
                                  ))}
                                <Box textAlign="center" p={2}>
                                  <input
                                    accept="*/*"
                                    style={{ display: "none" }}
                                    id={`file-input-${folder.name}`}
                                    multiple
                                    type="file"
                                    onChange={handleUpload}
                                  />
                                  <label htmlFor={`file-input-${folder.name}`}>
                                    <Button
                                      variant="contained"
                                      component="span"
                                    >
                                      Upload Document
                                    </Button>
                                  </label>
                                </Box>
                              </List>
                            </Collapse>
                          </React.Fragment>
                        ))}
                    <ListItemButton
                      className="folder"
                      onClick={() => setOpenCreateFolderModal(true)}
                    >
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add Folder" />
                    </ListItemButton>
                  </List>
                </Collapse>

                {/* Add Folder Modal */}
                <Dialog
                  open={openCreateFolderModal}
                  onClose={() => setOpenCreateFolderModal(false)}
                >
                  <DialogTitle>Add New Folder</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      To add a new folder to the current directory, please enter
                      the folder name below.
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Folder Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={newFolderName}
                      onChange={(e) => setNewFolderName(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenCreateFolderModal(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        createNewFolder(newFolderName);
                        setOpenCreateFolderModal(false);
                      }}
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>

                <ListItemButton
                  onClick={() => {
                    setSelectedFolder("IDdoc");
                    setOpenIDdoc(!openIDdoc);
                  }}
                >
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Identity Document Copy" />
                  {openIDdoc ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openIDdoc} timeout="auto" unmountOnExit>
                  <List className="files" component="div" disablePadding>
                    {loadingIdDoc && <CircularProgress />}
                    {!loadingIdDoc &&
                      idDocFiles.map((file, index) => (
                        <ListItem key={index} divider>
                          <ListItemIcon>
                            <AttachFileOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={file.name}
                            secondary={`Uploaded: ${
                              file.uploadDate
                                ? new Date(file.uploadDate).toLocaleString()
                                : "Unknown"
                            } by ${file.uploaderEmail || "Unknown"}`}
                          />
                          <ListItemIcon>
                            <IconButton
                              edge="end"
                              aria-label="download"
                              onClick={() => window.open(file.url, "_blank")}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </ListItemIcon>
                          {role.includes("2009") && (
                            <ListItemIcon>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  setFileToDelete({
                                    name: file.name,
                                    folder: "IDdoc",
                                  });
                                  setDeleteConfirmModalOpen(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemIcon>
                          )}
                        </ListItem>
                      ))}
                  </List>
                  <Box textAlign="center" p={2}>
                    <input
                      accept="*/*"
                      style={{ display: "none" }}
                      id="raised-button-file-IDdoc"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setUploadModalOpen(true);
                        setUploadModalMessage("Uploading document...");

                        handleFileUploadLer(
                          e,
                          id,
                          id2,
                          "IDdoc",
                          (newFiles) => {
                            setIdDocFiles(newFiles);
                            setUploadModalMessage(
                              "Document uploaded successfully!"
                            );
                            setTimeout(() => setUploadModalOpen(false), 2000);
                          },
                          currentUser // Add this line
                        ).catch((error) => {
                          setUploadModalMessage(
                            `Error uploading document: ${error.message}`
                          );
                          setTimeout(() => setUploadModalOpen(false), 2000);
                        });
                      }}
                    />
                    <label htmlFor="raised-button-file-IDdoc">
                      <Button variant="contained" component="span">
                        Upload Document
                      </Button>
                    </label>
                  </Box>
                </Collapse>

                <ListItemButton
                  onClick={() => {
                    setSelectedFolder("Certificates");
                    setOpenCertificates(!openCertificates);
                  }}
                >
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Certificates" />
                  {openCertificates ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCertificates} timeout="auto" unmountOnExit>
                  <List className="files" component="div" disablePadding>
                    {loadingCertificates && <CircularProgress />}
                    {!loadingCertificates &&
                      certificateFiles.map((file, index) => (
                        <ListItem key={index} divider>
                          <ListItemIcon>
                            <AttachFileOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={file.name}
                            secondary={`Uploaded: ${
                              file.uploadDate
                                ? new Date(file.uploadDate).toLocaleString()
                                : "Unknown"
                            } by ${file.uploaderEmail || "Unknown"}`}
                          />
                          <ListItemIcon>
                            <IconButton
                              edge="end"
                              aria-label="download"
                              onClick={() => window.open(file.url, "_blank")}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </ListItemIcon>
                          {role.includes("2009") && (
                            <ListItemIcon>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  setFileToDelete({
                                    name: file.name,
                                    folder: "Certificates",
                                  });
                                  setDeleteConfirmModalOpen(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemIcon>
                          )}
                        </ListItem>
                      ))}
                  </List>
                  <Box textAlign="center" p={2}>
                    <input
                      accept="*/*"
                      style={{ display: "none" }}
                      id="raised-button-file-Certificates"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setUploadModalOpen(true);
                        setUploadModalMessage("Uploading document...");

                        handleFileUploadLer(
                          e,
                          id,
                          id2,
                          "Certificates",
                          (newFiles) => {
                            setCertificateFiles(newFiles);
                            setUploadModalMessage(
                              "Document uploaded successfully!"
                            );
                            setTimeout(() => setUploadModalOpen(false), 2000);
                          },
                          currentUser // Add this line
                        ).catch((error) => {
                          setUploadModalMessage(
                            `Error uploading document: ${error.message}`
                          );
                          setTimeout(() => setUploadModalOpen(false), 2000);
                        });
                      }}
                    />
                    <label htmlFor="raised-button-file-Certificates">
                      <Button variant="contained" component="span">
                        Upload Document
                      </Button>
                    </label>
                  </Box>
                </Collapse>

                <ListItemButton
                  onClick={() => {
                    setSelectedFolder("CV");
                    setOpenCV(!openCV);
                  }}
                >
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Curriculum Vitae" />
                  {openCV ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCV} timeout="auto" unmountOnExit>
                  <List className="files" component="div" disablePadding>
                    {loadingCV && <CircularProgress />}
                    {!loadingCV &&
                      cvFiles.map((file, index) => (
                        <ListItem key={index} divider>
                          <ListItemIcon>
                            <AttachFileOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={file.name}
                            secondary={`Uploaded: ${
                              file.uploadDate
                                ? new Date(file.uploadDate).toLocaleString()
                                : "Unknown"
                            } by ${file.uploaderEmail || "Unknown"}`}
                          />
                          <ListItemIcon>
                            <IconButton
                              edge="end"
                              aria-label="download"
                              onClick={() => window.open(file.url, "_blank")}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </ListItemIcon>
                          {role.includes("2009") && (
                            <ListItemIcon>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  setFileToDelete({
                                    name: file.name,
                                    folder: "CV",
                                  });
                                  setDeleteConfirmModalOpen(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemIcon>
                          )}
                        </ListItem>
                      ))}
                  </List>
                  <Box textAlign="center" p={2}>
                    <input
                      accept="*/*"
                      style={{ display: "none" }}
                      id="raised-button-file-CV"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setUploadModalOpen(true);
                        setUploadModalMessage("Uploading document...");

                        handleFileUploadLer(
                          e,
                          id,
                          id2,
                          "CV",
                          (newFiles) => {
                            setCvFiles(newFiles);
                            setUploadModalMessage(
                              "Document uploaded successfully!"
                            );
                            setTimeout(() => setUploadModalOpen(false), 2000);
                          },
                          currentUser // Add this line
                        ).catch((error) => {
                          setUploadModalMessage(
                            `Error uploading document: ${error.message}`
                          );
                          setTimeout(() => setUploadModalOpen(false), 2000);
                        });
                      }}
                    />
                    <label htmlFor="raised-button-file-CV">
                      <Button variant="contained" component="span">
                        Upload Document
                      </Button>
                    </label>
                  </Box>
                </Collapse>
              </List>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>Loading...</Box>
      )}

      <Dialog
        open={deleteConfirmModalOpen}
        onClose={() => setDeleteConfirmModalOpen(false)}
      >
        <DialogTitle>Delete Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {fileToDelete.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmModalOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteFileFromFolder(
                id,
                id2,
                fileToDelete.folder,
                fileToDelete.name
              ).then((success) => {
                if (success) {
                  // Check the folder and update the appropriate state
                  if (fileToDelete.folder === "CV") {
                    setCvFiles(
                      cvFiles.filter((file) => file.name !== fileToDelete.name)
                    );
                  } else if (fileToDelete.folder === "Certificates") {
                    setCertificateFiles(
                      certificateFiles.filter(
                        (file) => file.name !== fileToDelete.name
                      )
                    );
                  } else if (fileToDelete.folder === "IDdoc") {
                    setIdDocFiles(
                      idDocFiles.filter(
                        (file) => file.name !== fileToDelete.name
                      )
                    );
                  }
                  // This covers any other files not categorized above, if applicable
                  else {
                    setFiles(
                      files.filter((file) => file.name !== fileToDelete.name)
                    );
                  }
                }
                setDeleteConfirmModalOpen(false);
              });
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteFolderConfirmModalOpen}
        onClose={() => setDeleteFolderConfirmModalOpen(false)}
      >
        <DialogTitle>Delete Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the folder "{folderToDelete.name}"
            and all of its contents?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteFolderConfirmModalOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              const success = await deleteFolder(id, id2, folderToDelete.name);
              if (success) {
                setActionModalMessage("Folder deleted successfully");
                setAssessmentFolders(
                  assessmentFolders.filter(
                    (f) => f.name !== folderToDelete.name
                  )
                );
              } else {
                setActionModalMessage("Failed to delete the folder");
              }
              setActionModalOpen(true);
              setDeleteFolderConfirmModalOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteAssessmentFileModalOpen}
        onClose={() => setDeleteAssessmentFileModalOpen(false)}
      >
        <DialogTitle>Delete Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{assessmentFileToDelete.name}" from
            the folder "{assessmentFileToDelete.folder}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAssessmentFileModalOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              // Logic to delete the assessment file goes here
              deleteAssessmentFile(
                id,
                id2,
                assessmentFileToDelete.folder,
                assessmentFileToDelete.name
              )
                .then((success) => {
                  if (success) {
                    setAssessmentFiles(
                      assessmentFiles.filter(
                        (file) => file.name !== assessmentFileToDelete.name
                      )
                    );
                    setActionModalMessage("File deleted successfully");
                  } else {
                    setActionModalMessage("Failed to delete the file");
                  }
                  setActionModalOpen(true);
                })
                .catch((error) => {
                  console.error("Error deleting file:", error);
                  setActionModalMessage(
                    `Error deleting file: ${error.message}`
                  );
                  setActionModalOpen(true);
                });

              setDeleteAssessmentFileModalOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ActionModal />

      <Dialog open={actionModalOpen} onClose={() => setActionModalOpen(false)}>
        <DialogTitle>Action Status</DialogTitle>
        <DialogContent>
          <DialogContentText>{actionModalMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setActionModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
