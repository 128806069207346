import { Box, IconButton, Menu, Typography } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import logo from "content/epsaconnect.jpg";
import "components/Navbar/index.scss";
// import { useAuth } from "setup/auth";
const Navbar = () => {
  //   const { logout  } = useAuth()

  async function logg() {
    // try {
    //   await logout()
    // } catch (error) {
    //   console.log(error)
    // }
  }
  return (
    <Box className="navbarBox" p={2}>
      {/* SEARCH BAR */}
      <Box className="logo">
        <img alt="profile-user" src={logo} />
        <Typography className="h6" variant="h6">
          {" "}
          Empowering People SA (Pty) Ltd
        </Typography>
      </Box>

      {/* ICONS */}
      <Box className="rightBox">
        {/* <IconButton onClick={logg}>
          <Typography className="innerText">
            Sign Out
          </Typography>
          <ExitToAppIcon className="innerButton" />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Navbar;
