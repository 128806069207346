import React from 'react'
export default async function EmailUsers(subject,message,bccs) {
  const url = 'https://api.mailbaby.net/mail/advsend';

// Encode the email content
// const encodedMessage = encodeURIComponent(message);

const options = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
    'X-API-KEY': 'gyVSp8S64hst65uOiSFZrrcuLQW1XBwMGVsfvF1OCMJLvK8NvZcar8SvexFReUfW6ZubFRXYx8wGWo47zZ6x88CnXluYx3gEx8SxqShakBRcjMhPP8MY3yczIxBgBOG9'
  },
  body: new URLSearchParams({
    subject: subject,
    body: message,
    from: 'admin@greenfoxinc.co.za',
    to: 'admin@excutech.co.za',
    replyto: 'admin@excutech.co.za',
    bcc: bccs
  }).toString()
};
    
    try {
      const response = await fetch(url, options);
    //    const data = await response.json();

      return response
    } catch (error) {
      return error
    }
 
}
