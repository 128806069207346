import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  CircularProgress,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useOutletContext } from "react-router-dom";
import { deleteSLAOrCommFile } from "setup/DBWrites";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FetchVendorData } from "setup/DbCalls";
import SendIcon from "@mui/icons-material/Send";
import "pages/VendorView/VendorView.scss";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import LMIS from "components/LMIS/LMIS";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import AddLearner from "components/Learner/addLearner";
import {
  fetchServiceLevelAgreementFiles,
  handleFileUpload,
  saveVendorDetailsToDB,
} from "setup/DBWrites";
import { useAuth } from "setup/Auth";

export default function VendorView() {
  const { role } = useOutletContext();
  const { id } = useParams();
  const [vendor, setVendor] = useState();
  const [open, setOpen] = useState(false);
  const [openCompanyEdit, setOpenCompanyEdit] = useState(false);
  const [openLMIS, setOpenLMIS] = useState(false);
  const [openSLA, setOpenSLA] = useState(false);
  const [slaFiles, setSlaFiles] = useState([]);
  const [slaLoading, setSlaLoading] = useState(false);
  const [openComm, setOpenComm] = useState(false);
  const [commFiles, setCommFiles] = useState([]);
  const [commLoading, setCommLoading] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadModalMessage, setUploadModalMessage] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({ name: "", folder: "" });
  const [deleteSuccessModalOpen, setDeleteSuccessModalOpen] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");
  const [currentSection, setCurrentSection] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccessModalOpen, setSaveSuccessModalOpen] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    async function fetchData() {
      const data = await FetchVendorData(id.slice(3));
      if (data) {
        setVendor(data);
      }
    }
    fetchData();
  }, [id]);

  const handleClickLMIS = () => setOpenLMIS(!openLMIS);
  const handleClick = () => setOpen(!open);
  const handleClickEditCompany = () => setOpenCompanyEdit(!openCompanyEdit);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setVendor((prevVendor) => ({
      ...prevVendor,
      [name]: value,
    }));
  };

  const onClickSave = async () => {
    if (!vendor) return;
    setIsSaving(true); // Start loading
    const cleanedId = id.replace("EMP", ""); // Remove "EMP" prefix from the id
    try {
      await saveVendorDetailsToDB(cleanedId, vendor);
      console.log("Vendor details saved successfully.");
      setIsSaving(false); // Stop loading
      setSaveSuccessModalOpen(true); // Show success dialog
      setOpenCompanyEdit(false);
    } catch (error) {
      console.error("Failed to save vendor details:", error);
      setIsSaving(false); // Ensure loading is stopped in case of an error
    }
  };

  const handleClickSection = async (section) => {
    const isOpen = section === "SLA" ? openSLA : openComm;
    const setOpen = section === "SLA" ? setOpenSLA : setOpenComm;
    const setFiles = section === "SLA" ? setSlaFiles : setCommFiles;
    const setLoading = section === "SLA" ? setSlaLoading : setCommLoading;
    const files = section === "SLA" ? slaFiles : commFiles;

    setOpen(!isOpen);

    if (!isOpen && files.length === 0) {
      setLoading(true);
      const folderName =
        section === "SLA" ? "Service Level Agreement" : "Communication";
      const fetchedFiles = await fetchServiceLevelAgreementFiles(
        id.replace("EMP", ""),
        folderName
      );
      setFiles(fetchedFiles);
      setLoading(false);
    }
  };

  const handleDeleteFile = async () => {
    const success = await deleteSLAOrCommFile(
      id.replace("EMP", ""),
      fileToDelete.folder,
      fileToDelete.name
    );
    if (success) {
      setDeleteSuccessMessage(
        `File ${fileToDelete.name} deleted successfully from ${fileToDelete.folder}`
      );
      setDeleteSuccessModalOpen(true);
      // Determine which file list to update based on the current section
      if (currentSection === "SLA") {
        setSlaFiles(slaFiles.filter((file) => file.name !== fileToDelete.name));
      } else if (currentSection === "Communication") {
        setCommFiles(
          commFiles.filter((file) => file.name !== fileToDelete.name)
        );
      }
    }
    setDeleteModalOpen(false); // Close the delete confirmation modal
  };

  return (
    <Box>
      <Box className="viewHeader">
        <h2>Vendor {id}</h2>
        {/* <AddLearner vendorId={id} /> */}
      </Box>
      {vendor ? (
        <Box>
          <Box className="viewContent">
            <Box className="viewContentInner">
              <List
                sx={{ width: "90%", bgcolor: "background.paper" }}
                component="nav"
              >
                {/* Company Details */}
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Company Details" />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {openCompanyEdit ? (
                    <List component="div" disablePadding>
                      {Object.entries(vendor).map(([key, value], index) => (
                        <ListItem key={index} divider>
                          <ListItemText
                            primary={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{key.replace(/_/g, " ")}</span>

                                <TextField
                                  key={key}
                                  name={key}
                                  className="textfield"
                                  // label={key
                                  //   .replace(/_/g, " ")
                                  //   .replace(/\b(\w)/g, (s) => s.toUpperCase())} // Capitalize label
                                  value={value}
                                  onChange={handleFormChange}
                                  fullWidth
                                  // margin="normal"
                                  variant="outlined"
                                />
                              </div>
                            }
                          />
                        </ListItem>
                      ))}
                      <ListItemButton
                        className="companyDetailsButton"
                        onClick={onClickSave}
                        disabled={isSaving} // Disable the button while saving
                      >
                        <Box className="companyDetailsButtonInner">
                          <ListItemIcon>
                            {isSaving ? (
                              <CircularProgress size={24} />
                            ) : (
                              <AddIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary="Save" />
                        </Box>
                      </ListItemButton>
                      <ListItemButton
                        className="companyDetailsButton"
                        onClick={() => {
                          handleClickEditCompany();
                        }}
                      >
                        <Box className="companyDetailsButtonInner">
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText primary="Cancel" />
                        </Box>
                      </ListItemButton>
                    </List>
                  ) : (
                    <List component="div" disablePadding>
                      {Object.entries(vendor).map(([key, value], index) => (
                        <ListItem key={index} divider>
                          <ListItemText
                            primary={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{key.replace(/_/g, " ")}</span>
                                <span>{value}</span>
                              </div>
                            }
                          />
                        </ListItem>
                      ))}
                      <ListItemButton
                        className="companyDetailsButton"
                        onClick={() => {
                          handleClickEditCompany();
                        }}
                      >
                        <Box className="companyDetailsButtonInner">
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText primary="Edit Company Details" />
                        </Box>
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>

                {/* Service Level Agreement */}
                <ListItemButton onClick={() => handleClickSection("SLA")}>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Service Level Agreement" />
                  {openSLA ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSLA} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {slaLoading ? (
                      <CircularProgress />
                    ) : (
                      slaFiles.map((file, index) => (
                        <ListItem
                          key={index}
                          divider
                          style={{ marginLeft: "30px" }}
                        >
                          <ListItemIcon>
                            <AttachFileOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={file.name}
                            secondary={`Uploaded: ${
                              file.uploadDate
                                ? new Date(file.uploadDate).toLocaleString()
                                : "Unknown"
                            } by ${file.uploaderEmail || "Unknown"}`}
                          />
                          <ListItemIcon>
                            <IconButton
                              edge="end"
                              aria-label="download"
                              href={file.url}
                              target="_blank"
                            >
                              <DownloadIcon />
                            </IconButton>
                          </ListItemIcon>
                          <ListItemIcon>
                            {role.includes("2009") && (
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  setFileToDelete({
                                    name: file.name,
                                    folder: "Service Level Agreement",
                                  });
                                  setCurrentSection("SLA"); // Setting the current section
                                  setDeleteModalOpen(true); // Opening the delete confirmation modal
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </ListItemIcon>
                        </ListItem>
                      ))
                    )}
                    <input
                      type="file"
                      id="upload-sla-file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleFileUpload(
                          e,
                          id.replace("EMP", ""),
                          setSlaFiles,
                          "Service Level Agreement",
                          setUploadModalOpen,
                          setUploadModalMessage,
                          currentUser // Add this line
                        )
                      }
                    />
                    <label htmlFor="upload-sla-file">
                      <Button variant="contained" component="span" size="small">
                        Upload new Document
                      </Button>
                    </label>
                  </List>
                </Collapse>

                {/* Communication */}
                <ListItemButton
                  onClick={() => handleClickSection("Communication")}
                >
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Communication" />
                  {openComm ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openComm} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {commLoading ? (
                      <CircularProgress />
                    ) : (
                      commFiles.map((file, index) => (
                        <ListItem
                          key={index}
                          divider
                          style={{ marginLeft: "30px" }}
                        >
                          <ListItemIcon>
                            <AttachFileOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={file.name}
                            secondary={`Uploaded: ${
                              file.uploadDate
                                ? new Date(file.uploadDate).toLocaleString()
                                : "Unknown"
                            } by ${file.uploaderEmail || "Unknown"}`}
                          />
                          <ListItemIcon>
                            <IconButton
                              edge="end"
                              aria-label="download"
                              href={file.url}
                              target="_blank"
                            >
                              <DownloadIcon />
                            </IconButton>
                          </ListItemIcon>

                          <ListItemIcon>
                            {role.includes("2009") && (
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  setFileToDelete({
                                    name: file.name,
                                    folder: "Communication",
                                  });
                                  setCurrentSection("Communication"); // Setting the current section
                                  setDeleteModalOpen(true); // Opening the delete confirmation modal
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </ListItemIcon>
                        </ListItem>
                      ))
                    )}
                    <input
                      type="file"
                      id="upload-comm-file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleFileUpload(
                          e,
                          id.replace("EMP", ""),
                          setCommFiles,
                          "Communication",
                          setUploadModalOpen,
                          setUploadModalMessage,
                          currentUser // Add this line
                        )
                      }
                    />
                    <label htmlFor="upload-comm-file">
                      <Button variant="contained" component="span" size="small">
                        Upload new Document
                      </Button>
                    </label>
                  </List>
                </Collapse>

                {/* LMIS */}
                <ListItemButton onClick={handleClickLMIS}>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="LMIS" />
                  {openLMIS ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openLMIS} timeout="auto" unmountOnExit>
                  <LMIS vendorId={id} />
                </Collapse>

                {/* Add more sections like Purchase Orders and Invoices as needed */}
                {/* Example for a static section (not expanded in this sample) */}
                <ListItemButton>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Purchase Orders" />
                </ListItemButton>

                <ListItemButton>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Invoices" />
                </ListItemButton>

                <ListItemButton>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Quotes" />
                </ListItemButton>
              </List>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
        <DialogTitle>Upload Status</DialogTitle>
        <DialogContent>
          <DialogContentText>{uploadModalMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Delete File</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file: {fileToDelete.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              const success = await deleteSLAOrCommFile(
                id.replace("EMP", ""),
                fileToDelete.folder,
                fileToDelete.name
              );
              if (success) {
                // Update your state to reflect the deletion
                // This may involve re-fetching the files for the folder or filtering the current file list
                // For example, if you have a state named slaFiles or commFiles, you would filter it here
              }
              setDeleteModalOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Delete File</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file: {fileToDelete.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button onClick={() => handleDeleteFile()}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={saveSuccessModalOpen}
        onClose={() => setSaveSuccessModalOpen(false)}
      >
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vendor details have been saved successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveSuccessModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
