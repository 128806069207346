import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box,Button, TextField, CircularProgress } from "@mui/material";
import "components/Learner/addLearner.scss";
import { writeLearnerData } from "setup/DBWrites";

const AddLearner = ({ open, handleClose, vendorId }) => {
  // console.log("AddLearner open prop:", open);
  const [learnerDetails, setLearnerDetails] = useState({
    Name: "",
    Surname: "",
    ID_Number: "",
    Email_Address: "",
    Contact_Number: "",
    Occupation: "",
    Work_Email: "",
    Work_Number: "",
    Race: "",
    Nationality: "",
    Disabilities: "",
    Highest_Qualification: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false); // New state for success dialog

  const handleChange = (event, key) => {
    setLearnerDetails({ ...learnerDetails, [key]: event.target.value });
  };

  const handleAddLearner = async () => {
    setIsLoading(true); // Start loading
    const safeLearnerDetails = Object.keys(learnerDetails).reduce(
      (acc, key) => {
        acc[key] = learnerDetails[key] === undefined ? "" : learnerDetails[key];
        return acc;
      },
      {}
    );

    try {
      await writeLearnerData(vendorId, safeLearnerDetails);
      setIsLoading(false); // Stop loading
      setShowSuccessDialog(true); // Show success dialog
    } catch (error) {
      console.error("Error adding LMIS document: ", error);
      setIsLoading(false); // Stop loading in case of error
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="title">{"Add New Learner"}</DialogTitle>
        <DialogContent className="contentDialog">
          {/* <DialogContentText className="body"> */}
            {Object.keys(learnerDetails).map((key) => (
              <TextField
                key={key}
                id={key}
                label={key.replace(/_/g, " ")} // Add space before capital letters and trim
                variant="filled"
                style={{ margin: 8 }}
                fullWidth
                value={learnerDetails[key]}
                onChange={(event) => handleChange(event, key)}
              />
            ))}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleAddLearner}>Add Learner</Button>
          )}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
      >
        <DialogTitle>{"Learner Added Successfully"}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSuccessDialog(false);
              handleClose(true);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
  );
};

export default AddLearner;
