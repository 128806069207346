import { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import { Link, useOutletContext } from "react-router-dom";
import "components/Sidebar/Sidebar.scss";
// import pfp from "content/pfpnone.jpg";
// import { useAuth } from "setup/auth";

// MUI ICONS IMPORT
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { useAuth } from "setup/Auth";
// END MUI ICONS IMPORT

const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      active={selected === title}
      onClick={() => setSelected(title)}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

function Sidebar1(role) {
  const { logout } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("VendorList");
  // const { role } = useOutletContext();
  // console.log(role.role);

  async function logg() {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Sidebar
      className="Sidebar"
      collapsed={isCollapsed}
      // collapsedWidth={"60px"}
    >
      <Menu iconShape="square">
        {/* LOGO AND MENU ICON */}
        <MenuItem
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
          style={{
            margin: "5px 0 2px 0",
            // color: colors.grey[100],
          }}
        >
          {!isCollapsed && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              ml="15px"
            >
              <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <MenuOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </MenuItem>

        <Box paddingLeft={isCollapsed ? undefined : "5%"}>
          {!isCollapsed && (
            <Typography variant="h6" sx={{ m: "15px 0 5px 20px" }}>
              Vendor
            </Typography>
          )}
          <Item
            title="Vendor List"
            to="/VendorList"
            icon={<BusinessOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Item
            title="Create Vendor"
            to="/CreateVendor"
            icon={<DomainAddOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
        <Box paddingLeft={isCollapsed ? undefined : "5%"}>
          {!isCollapsed && (
            <Typography variant="h6" sx={{ m: "15px 0 5px 20px" }}>
              Finance
            </Typography>
          )}
          <Item
            title="Cashflow Budgeting"
            to="#"
            icon={<PaymentsOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Item
            title="Quote & Invoice"
            to="#"
            icon={<DescriptionOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Quote & Invoice Tracking"
            to="#"
            icon={<FindInPageOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
        <Box paddingLeft={isCollapsed ? undefined : "5%"}>
          {!isCollapsed && (
            <Typography variant="h6" sx={{ m: "15px 0 5px 20px" }}>
              Human Resources
            </Typography>
          )}
          <Item
            title="Employees"
            to="#"
            icon={<PeopleOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Item
            title="Facilitators"
            to="#"
            icon={<SupervisorAccountOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Shareholders"
            to="#"
            icon={<Groups2OutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>

        {
          // eslint-disable-next-line eqeqeq
          role.role.includes("2009") && (
            <Box paddingLeft={isCollapsed ? undefined : "5%"}>
              {!isCollapsed && (
                <Typography variant="h6" sx={{ m: "15px 0 5px 20px" }}>
                  User Management
                </Typography>
              )}
              <Item
                title="Manage Users"
                to="/Usermanagement"
                icon={<ManageAccountsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )
        }
        <Box sx={{ mt: "20px" }} paddingLeft={isCollapsed ? undefined : "10%"}>
          <MenuItem onClick={logg} icon={<ExitToAppIcon />}>
            <Typography>Sign Out</Typography>
          </MenuItem>
        </Box>
      </Menu>
    </Sidebar>
  );
}

export default Sidebar1;
