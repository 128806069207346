import React, { useState, useRef } from "react";
// import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useAuth } from "setup/Auth";
// import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import 'pages/ForgotPass/index.scss';
import epsa from 'content/epsaconnect.jpg'

export default function ForgotPassword() {
  const emailRef = useRef();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { resetPassword } = useAuth();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    setLoading(true);

    try {
      await resetPassword( emailRef.current.value);
      setMessage("Password reset email sent. Please check your inbox.");
      setLoading(false);
    } catch (error) {
      setError("Failed to send password reset email. Please try again.");
      console.error("Error sending password reset email:", error);
      setLoading(false);
    }
  };

  return (
    <Box className="forgot-password">
      <Box className="leftbox">
        <Box
          className="image"
          component="img"
          alt="The house from the offer."
          src={epsa}
        />
      </Box>
      <Box className="forgotPasswordBox">
        <Box className="forgotPasswordInnerBox">
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              {message && <Alert severity="success">{message}</Alert>}
              {error && <Alert severity="error">{error}</Alert>}
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  inputRef={emailRef}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                  Send Password Reset Email
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/login" variant="body2">
                      Remember your password? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
