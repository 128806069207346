// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKkP8EaRZUvZgCeryw-hw8yhwsQWyyRg4",
  authDomain: "epsaconnect.firebaseapp.com",
  projectId: "epsaconnect",
  storageBucket: "epsaconnect.appspot.com",
  messagingSenderId: "550654462699",
  appId: "1:550654462699:web:a7d90f54997dd447ae8cae"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
export default firebaseApp;
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export{db} ;
