import React, { useContext, useState, useEffect } from "react";
import { auth } from "setup/firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  signOut,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  Timestamp,
  FieldValue,
  Filter,
  getDocs,
  query,
  where,
  limit,
} from "firebase/firestore";
import firebaseApp from "setup/firebase";
import { useRef } from "react";
const db = getFirestore(firebaseApp);
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userInfo, setUserInfo] = useState();

  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  async function GetUserInfo(user) {
    if (user) {
      try {
        const ref = doc(db, "Users", user.email);
        const docSnap = await getDoc(ref);
        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
          return docSnap.data();
        } else {
          setUserInfo([]);
          return [];
        }
      } catch (error) {
        console.log(error);
        setUserInfo([]);
        return [];
      }
    }
  }

  function updateUserEmail(email) {
    return updateEmail(currentUser, email);
  }

  function updateuserPassword(password) {
    return updatePassword(currentUser, password);
  }

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        GetUserInfo(user)
          .then(() => {
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false); // Ensure loading is set to false even if there's an error.
          });
      } else {
        setLoading(false); // Also set loading false here if there's no user (user is logged out)
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateUserEmail,
    updateuserPassword,
    userInfo,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
