import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Button,
  TextField,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "pages/CreateVendor/CreateVendor.scss";
import { Label } from "@mui/icons-material";
import { db } from "setup/firebase.js";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { createFolder } from "setup/DBWrites.js";

import { useNavigate } from "react-router-dom";

export default function CreateVendor() {
  const navigate = useNavigate();

  const [folderName, setFolderName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    Company_Name: "",
    Registration_Number: "",
    Vat_Number: "",
    Physical_Address: "",
    Postal_Adress: "",
    Company_Contact_Number: "",
    Company_Email_Adress: "",
    Industry: "",
    Contact_Person_Name: "",
    Designation: "",
    Contact_Number: "",
    Email_Adress: "",
    Financial_Department_Responsible_Person: "",
    Financial_Person_Contact_Number: "",
    Financial_Person_Email_Adress: "",
  });

  const handleChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading indicator
    let newDocId = "001"; // Assume this is the initial ID
    try {
      const lastDocQuery = query(
        collection(db, "vendors"),
        orderBy("docId", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(lastDocQuery);

      if (!querySnapshot.empty) {
        const lastDoc = querySnapshot.docs[0];
        const lastDocId = lastDoc.data().docId;

        const numericId = parseInt(lastDocId, 10);
        if (!isNaN(numericId)) {
          newDocId = (numericId + 1).toString().padStart(3, "0");
        }
      }

      // Save the document with newDocId and formData
      await setDoc(doc(db, "vendors", newDocId), {
        ...formData,
        docId: newDocId,
      });
      console.log("Document written with ID: ", newDocId);

      // Attempt to create a folder with either the folderName provided or the newDocId
      await createFolder(folderName || newDocId);
      setIsModalOpen(true); // Open success modal on successful folder creation
    } catch (e) {
      console.error("Error adding document: ", e);
      // Optionally, you could set an error state here and show it to the user
    }
    setIsLoading(false); // Stop loading indicator
  };

  const textFields = [
    {
      id: "Company_Name",
      label: "Company Name",
      variant: "filled",
      stateKey: "Company_Name",
    },
    {
      id: "Registration_Number",
      label: "Registration Number",
      variant: "filled",
      stateKey: "Registration_Number",
    },
    {
      id: "Vat_Number",
      label: "Vat Number",
      variant: "filled",
      stateKey: "Vat_Number",
    },
    {
      id: "Physical_Address",
      label: "Physical Address",
      variant: "filled",
      stateKey: "Physical_Address",
    },
    {
      id: "Postal_Adress",
      label: "Postal Address",
      variant: "filled",
      stateKey: "Postal_Adress",
    },
    {
      id: "Company_Contact_Number",
      label: "Contact Number",
      variant: "filled",
      stateKey: "Company_Contact_Number",
    },
    {
      id: "Company_Email_Adress: ",
      label: "Email_Adress",
      variant: "filled",
      stateKey: "Company_Email_Adress",
    },
    {
      id: "Industry",
      label: "Industry",
      variant: "filled",
      stateKey: "Industry",
    },
    {
      id: "Contact_Person_Name",
      label: "Contact_Person_Name",
      variant: "filled",
      stateKey: "Contact_Person_Name",
    },
    {
      id: "Designation",
      label: "Designation",
      variant: "filled",
      stateKey: "Designation",
    },
    {
      id: "Contact_Number",
      label: "Contact Number",
      variant: "filled",
      stateKey: "Contact_Number",
    },
    {
      id: "Email_Adress",
      label: "Email Address",
      variant: "filled",
      stateKey: "Email_Adress",
    },
    {
      id: "Financial_Department_Responsible_Person",
      label: "Financial Department Responsible Person",
      variant: "filled",
      stateKey: "Financial_Department_Responsible_Person",
    },
    {
      id: "Financial_Person_Contact_Number",
      label: "Contact Number",
      variant: "filled",
      stateKey: "Financial_Person_Contact_Number",
    },
    {
      id: "Financial_Person_Email_Adress",
      label: "Email Address",
      variant: "filled",
      stateKey: "Financial_Person_Email_Adress",
    },
  ];

  return (
    <Box className="vendorCreate">
      <Box className="vendorlable">Add your new Vender</Box>
      <Box className="CreateBox">
        {textFields.map((field, index) => (
          <TextField
            key={index}
            id={field.id}
            label={field.label}
            variant={field.variant}
            value={formData[field.stateKey]}
            onChange={(e) => handleChange(e, field.stateKey)}
          />
        ))}
      </Box>
      <Box className="SubmitBox">
        <Button
          className="SubmitButton"
          variant="outlined"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Add Vendor
          {isLoading && <CircularProgress size={24} />}
        </Button>
      </Box>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">Vendor Added</DialogTitle>
        <DialogContent>
          <DialogContentText id="success-dialog-description">
            The vendor has been added successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false);
              navigate("/VendorList");
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
