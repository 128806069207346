import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "pages/VendorList/VendorList.scss";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GetVendorList } from "setup/DbCalls";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
export default function VendorList() {
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [data, setData] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "EMP Number",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Company_Name",
      headerName: "Company",
      width: 250,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "Contact_Number",
      headerName: "Contact Number",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Email_Adress",
      headerName: "Email",
      width: 250,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "Financial_Person_Contact_Number",
      headerName: "Financial Contact Number",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Financial_Person_Email_Adress",
      headerName: "Financial Email",
      width: 250,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "Registration_Number",
      headerName: "Registration Number",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Vat_Number",
      headerName: "Vat Number",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleClickOpen = (rowSelected) => {
    navigate(`/vendorview/${rowSelected.id}`);
  };
  const exportToExcel = (apiRef) => {
    const columns = apiRef.current.getAllColumns();
    const headers = columns.map((column) => column.headerName);

    const data = apiRef.current.getRowModels();
    const rows = Array.from(data.values()).map((row) =>
      columns.map((column) => row[column.field])
    );

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "VendorList");

    // Apply styling
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1";
      if (!worksheet[address]) continue;
      worksheet[address].s = {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { fgColor: { rgb: "4472C4" } },
        alignment: { horizontal: "center", vertical: "center" },
      };
    }

    // Auto-size columns
    const colWidths = rows[0].map((_, i) => ({
      wch: Math.max(
        ...rows.map((row) => (row[i] ? row[i].toString().length : 0)),
        headers[i].length
      ),
    }));
    worksheet["!cols"] = colWidths;

    // Set row height for header
    worksheet["!rows"] = [{ hpt: 25 }];

    // Add borders to all cells
    // for (let R = range.s.r; R <= range.e.r; ++R) {
    //   for (let C = range.s.c; C <= range.e.c; ++C) {
    //     const address = XLSX.utils.encode_cell({ r: R, c: C });
    //     if (!worksheet[address]) worksheet[address] = {};
    //     worksheet[address].s = worksheet[address].s || {};
    //     worksheet[address].s.border = {
    //       top: { style: "thin", color: { auto: 1 } },
    //       right: { style: "thin", color: { auto: 1 } },
    //       bottom: { style: "thin", color: { auto: 1 } },
    //       left: { style: "thin", color: { auto: 1 } },
    //     };
    //   }
    // }

    // Apply zebra striping
    // for (let R = range.s.r + 1; R <= range.e.r; ++R) {
    //   const fillColor = R % 2 === 0 ? "F2F2F2" : "FFFFFF";
    //   for (let C = range.s.c; C <= range.e.c; ++C) {
    //     const address = XLSX.utils.encode_cell({ r: R, c: C });
    //     if (!worksheet[address]) worksheet[address] = {};
    //     worksheet[address].s = worksheet[address].s || {};
    //     worksheet[address].s.fill = { fgColor: { rgb: fillColor } };
    //   }
    // }

    // Generate filename with current date
    const currentDate = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
    const fileName = `VendorList_${currentDate}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };
  function CustomToolbar() {
    const apiRef = useGridApiContext();

    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExportContainer>
          <Button
            onClick={() => exportToExcel(apiRef)}
            startIcon={<DownloadIcon />}
          >
            Export Excel
          </Button>
          <GridPrintExportMenuItem
            options={{
              hideFooter: true,
              hideToolbar: true,
              pageStyle: "@page { size: landscape A4; margin: 5mm; }",
            }}
          />
        </GridToolbarExportContainer>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  return (
    <Box className="vendorList">
      <Box className="grid">
        <DataGrid
          sx={{
            "@media print": {
              ".MuiDataGrid-main": {
                width: "fit-content",
                fontSize: "15px",
                overflow: "visible",
              },
              marginBottom: 100,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              maxHeight: "130px !important",
            },
            "& .MuiDataGrid-columnHeadersInner": {
              height: "100% !important",
            },
            "& .MuiDataGrid-columnHeaderRow": {
              height: "100% !important",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            },
          }}
          className="datagrid"
          rows={GetVendorList()}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 30, 50, 100]}
          onRowClick={handleClickOpen}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              csvOptions: {
                delimiter: ",",
                utf8WithBom: true,
                allColumns: true,
                fileName: "VendorList",
              },
            },
          }}
          ignoreDiacritics={true}
        />
      </Box>
    </Box>
  );
}
