import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetVendorLMISList } from "setup/DbCalls";

import AddLearner from "components/Learner/addLearner";

const LMIS = (props) => {
  const navigate = useNavigate();
  const { id: vendorId } = useParams(); // Get vendor ID from URL parameters

  const [openDialog, setOpenDialog] = useState(false);
  const { limsRecords, loading, error } = GetVendorLMISList(vendorId.slice(3));

  const modifiedRecords = limsRecords.map((record) => ({
    ...record,
    combinedId: `${vendorId}/${record.id}`, // Combine vendor ID and student ID
  }));

  const handleClickOpen = (rowSelected) => {
    navigate(`/vendorview/${vendorId}/${rowSelected.id}`);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box className="LMIS">
      <Box className="LMISDB" style={{ height: 250, width: "100%" }}>
        <DataGrid
          loading={loading}
          error={error}
          columns={[
            {
              field: "combinedId",
              headerName: "Vendor/Student ID",
              width: 200,
            }, // New column for combined ID
            { field: "Name", headerName: "Name", width: 150 },
            { field: "Surname", headerName: "Surname", width: 150 },
            { field: "ID_Number", headerName: "ID Number", width: 150 },
            {
              field: "Contact_Number",
              headerName: "Contact Number",
              width: 120,
            },
            { field: "Email_Address", headerName: "Email", width: 200 },

            // Add other fields as necessary
          ]}
          rows={modifiedRecords}
          onRowClick={handleClickOpen}
          pageSize={5}
          rowsPerPageOptions={[5]}
          // checkboxSelection
        />
      </Box>
      <Box className="LMIS">
        <Button variant="outlined" onClick={handleDialogOpen}>
          Add Learner
        </Button>
        <AddLearner
          open={openDialog}
          handleClose={handleDialogClose}
          vendorId={vendorId}
        />
      </Box>
    </Box>
  );
};

export default LMIS;

// The rest of the code for VendorView remains the same
