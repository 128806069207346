import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
} from "@mui/material";
import "pages/UserManagement/Usermanagement.scss";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddUserToDB from "setup/DBWrites";
import { DbManagementCall } from "setup/DbCalls";
import { DeleteUserFromDB } from "setup/DBWrites";
import EmailUsers from "components/EmailServer/Email";
// import { Dblocations } from 'setup/db_calls';
// import AddUserToDB from 'setup/db_writes';
// import { DeleteUserFromDB } from 'setup/db_writes';
// import EmailUsers from 'components/emailUsers';

export default function AdminUserMan() {
  const [typeResult, setTypeResult] = useState("");
  const [typeYear, setTypeYear] = useState("");
  const [userError, setUserError] = useState(null);
  const [userSuccess, setUserSuccess] = useState(false);
  const [userEmailSuccess, setUserEmailSuccess] = useState(null);
  const [userDelSuccess, setUserDelSuccess] = useState(false);
  const [userDelError, setUserDelError] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [userDB, setUserDB] = useState([]);
  // const [userErrorMess, setUserErrorMess] = useState();
  const [emailResult, setEmailResult] = useState("");
  const [open, setOpen] = useState(false);
  const thisyear = new Date().getFullYear();
  const nextyear = thisyear + 1;
  const nextnextyear = thisyear + 2;

  useEffect(() => {
    // console.log('Reload.');
    const GetInfo = async () => {
      try {
        const response = await DbManagementCall();
        setUserDB(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    GetInfo();
  }, [userSuccess, userDelSuccess]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleYes = () => {
    console.log(rowSelectionModel);
    const deleteted = DeleteUserFromDB(rowSelectionModel);
    if (deleteted === true) {
      setUserDelSuccess(true);
    } else {
      setUserDelError(true);
    }
    setOpen(false);
  };

  const handleChangeUserEmail = (event) => {
    setEmailResult(event.target.value);
  };
  const handleChangeUserType = (event) => {
    setTypeResult(event.target.value);
  };

  function addUser() {
    setUserEmailSuccess(null);
    setUserSuccess(false);
    setUserError(null);
    if (
      emailResult !== null &&
      emailResult.trim() !== "" &&
      typeResult !== null &&
      typeResult.trim() !== ""
    ) {
      try {
        AddUserToDB(emailResult, typeResult)
          .then(function (response) {
            if (response === "success") {
              //subject,message,bccs
              setUserSuccess(true);
              const mess = `
          Welcome to the EPSA family! We're excited to have you on board.<br><br> 
          
          To ensure a smooth onboarding process, please follow the steps outlined below:<br><br> 
        
          <strong>** 1.Sign Up: **</strong><br> 
          Click on the following link to sign up using your email and create your password:<br> 
          <a href="https://www.epsa.excutech.co.za/SignUp">Sign Up</a><br><br> 
          
          <strong>** 2.Login: **</strong><br> 
          Once you've completed the sign-up process, log in to the Symco website using this link:<br>  
          <a href="https://www.epsa.excutech.co.za/login">Login</a><br><br> 

          Looking forward to a great journey together!<br><br> 
        
          Best regards,<br> 
          The EPSA Team`;
              try {
                EmailUsers("Welcome EPSA User", mess, emailResult).then(
                  function (response2) {
                    if (response2.status === 400) {
                      setUserError(
                        "[INPUT PARAMETER ERROR]There was a Error Sending the Email."
                      );
                    }
                    if (response2.status === 401) {
                      setUserError(
                        "There was a Error Sending the Email Please contact ExcuTech"
                      );
                    }
                    if (response2.status === 200) {
                      setUserEmailSuccess("Sent!");
                      setUserError(null);
                    } else {
                      setUserError("There was a Error Sending the Email ");
                    }
                  }
                );
              } catch (error) {
                if (error.code === 400) {
                  setUserError(
                    "[INPUT PARAMETER ERROR]There was a Error Sending the Email."
                  );
                }
                if (error.code === 401) {
                  setUserError(
                    "There was a Error Sending the Email Please contact ExcuTech"
                  );
                } else {
                  setUserError("There was a Error Sending the Email ");
                }
              }
            } else {
              setUserSuccess(false);
              setUserError("Error Creating User");
            }
          })
          .catch((err) => {
            console.log("Error Added to DB" + err);
          });
      } catch (error) {
        console.log("Error Added to DB" + error);
      }
    } else {
      setUserSuccess(false);
      setUserError("Please make sure Email, User Type");
    }
  }

  return (
    <Box className="adminUserMan">
      <Box className="addUser">
        <Box className="addUser1">
          <TextField
            id="outlined-helperText"
            label="User Email"
            helperText="example@example.com"
            className="userInput"
            onChange={handleChangeUserEmail}
          />
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">
              User Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={typeResult}
              label="UserType"
              onChange={handleChangeUserType}
              className="userInput"
            >
              <MenuItem value={"Admin"}>Admin</MenuItem>
              <MenuItem value={"User"}>User</MenuItem>
            </Select>
            <FormHelperText>Please select User Type</FormHelperText>
          </FormControl>
          <Button
            className="userInput"
            variant="outlined"
            onClick={addUser}
            startIcon={<PersonAddAltOutlinedIcon />}
          >
            Add User
          </Button>
        </Box>
        <Box className="userMessageDisplay">
          {userError && (
            <Alert variant="outlined" severity="error">
              {userError}
            </Alert>
          )}
          {userSuccess && (
            <Alert variant="outlined" severity="success">
              successfully added!
            </Alert>
          )}
          {userEmailSuccess && (
            <Alert variant="outlined" severity="success">
              Email: {userEmailSuccess}
            </Alert>
          )}
        </Box>
      </Box>

      <Box className="checkUsers">
        <Box className="db">
          <Box sx={{ height: "100%", width: "100%" }}>
            <DataGrid
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              columns={[
                {
                  field: "Email",
                  valueGetter: (params) => params.row.id,
                  minWidth: 300,
                },
                { field: "Role", valueGetter: (params) => params.row.type },
              ]}
              rows={userDB}
              getRowId={(row) => row.id}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          </Box>
        </Box>
        <Box className="deleteButton">
          <Button
            onClick={handleClickOpen}
            className="userInput"
            color="error"
            variant="outlined"
            startIcon={<DeleteForeverOutlinedIcon />}
          >
            Delete Selected Users
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to delete"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={handleYes} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Box className="userMessageDisplay">
            {userDelError && (
              <Alert variant="outlined" severity="error">
                Delete Unsuccessful
              </Alert>
            )}
            {userDelSuccess && (
              <Alert variant="outlined" severity="success">
                successfully Deleted!
              </Alert>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
